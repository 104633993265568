import jExec from "../../utils/jExec";

import css from './Stats.module.scss'

import { useState, useEffect } from "react"

export default function Stats() {
    const [data, setData] = useState(false) ;

    const load = async () => {
        const loaded = await jExec("test/used");
        setData(loaded) ;
    }
    useEffect(() => {
        load() ;
    },[])

    if (!data) return null ;

    return <div className={css.main}>
        <h1>Статистика</h1>
        <div>Использовано кодов: {data.codes}</div>
        <div>Создано схем: {data.used}</div>
        <div>
            <table cellSpacing={0} cellPadding={0}>
                <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Кодов</th>
                        <th>Схем</th>
                    </tr>
                </thead>
                <tbody>
                    {data.list.map((v,i) => 
                    <tr key={i}><td>{v.date}</td><td>{v.codes}</td><td>{v.used}</td></tr>
                    )}
                </tbody>
            </table>
        </div>
    </div>
}