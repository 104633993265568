import { serialize } from 'object-to-formdata';
import { apiURL, noUTF } from '../config';

export const setAuthKey = (authKey) => {
    if (!authKey) localStorage.removeItem('authKey') ;
    else localStorage.setItem('authKey', authKey) ;
}

export default async function jExec(m, payload) {
    m = '' + m;
    if (!payload) payload = {};

    if (!noUTF) payload.utf = 1;

    let FD = serialize(payload);

    let options = {
        method: 'POST',
        body: FD,
        headers: {},
    };
    const authKey = localStorage.getItem('authKey') ;
    if (authKey) options.headers['Authorization'] = authKey;

    try {
        const response = await fetch(apiURL + m, options);
        let x = await response.json();
        if (x.response) console.error(x.response);
        return x;
    } catch (e) {
        console.error(e);
        return { result: false, error: 'Ошибка сервера' };
    }
}

export async function jsonExec(m, payload) {
    m = '' + m;
    if (!payload) payload = {};

    if (!noUTF) payload.utf = 1;

    let options = {
        method: 'POST',
        body: JSON.stringify(payload),
        headers: { 'Content-type': 'application/json' },
    };
    const authKey = localStorage.getItem('authKey') ;
    if (authKey) options.headers['Authorization'] = authKey;

    let text = '' ;
    try {
        const response = await fetch(apiURL + m, options);
        text = await response.text() ;
        let x = JSON.parse(text) ;
        //let x = await response.json();
        if (x.error_msg) console.error (x.error_msg) ;
        if (x.response) console.warn(x.response);
        return x;
    } catch (e) {
        console.log(text) ;
        console.error(e);
        return { result: false, error: 'Ошибка сервера' };
    }
}