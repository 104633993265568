import React from "react";
import ReactInputMask from "react-input-mask";
import Inputbox from "../../shared/Inputbox";
import { useState, useEffect, useRef } from "react";
import validateEmail from "../../utils/validateEmail";
import jExec from "../../utils/jExec";
import { getDiskFilename } from "../../shared/DiskImage";

import css from "./Create.module.scss";

export default function Submit(props) {
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [valid, setValid] = useState(false);
	const [saving, setSaving] = useState(false);

	const [working, setWorking] = useState(false);

	const [brightness, setBrightness] = useState(0);
	const [contrast, setContrast] = useState(0);

	const onChangeTimeout = useRef();

	const onPhoneChange = (ev) => {
		setPhone(ev.target.value);
	};

	useEffect(() => {
		const phoneOk = /\+7 \([\d]{3}\) [\d]{3}-[\d]{2}-[\d]{2}/.test(phone);
		const emailOk = validateEmail(email);
		const isOk = (phone || email) && ((phone && phoneOk) || !phone) && ((email && emailOk) || !email);
		if (isOk != valid) setValid(isOk);
	}, [phone, email]);

	const onSubmit = async () => {
		setSaving(true);
		const done = await jExec("test/submit", { phone, email, code: props.code, result: props.result });
		if (done.result || true) {
			props.onSubmitted(done.schemes)
		}
		setSaving(false);
	};

	const onBrightnessChange = (ev) => {
		setBrightness(ev.target.value)
		onChange();
	}

	const onContractChange = (ev) => {
		setContrast(ev.target.value)
		onChange();
	}

	const doChanges = async () => {
		setWorking(true) ;
		const done = await jExec("test/go2", { result: props.result, brightness, contrast });
		if (done.hash) {
			props.onChange(done.hash) ;
		}
		setWorking(false) ;
	}

	const onChange = () => {
		if (onChangeTimeout.current) clearTimeout(onChangeTimeout.current)
		onChangeTimeout.current = setTimeout(doChanges, 1000);
	}


	console.log(props);

	return (<>
		<div className={css.result}>
			<div className={css.image + (working ? ' ' + css.working : '')}>
				<img src={getDiskFilename(props.result, "512x")} />
			</div>
			<div>Яркость</div>
			<input disabled={working} type="range" min={-60} max={60} value={brightness} onChange={onBrightnessChange} />
			<div>Контраст</div>
			<input disabled={working} type="range" min={-60} max={60} value={contrast} onChange={onContractChange} />
		</div>
		<div className={css.submit + "  p-10 flex-center"} >
			<div className="mb20">Введите E-Mail, или то и то, чтобы мы отправили Вам инструкцию по сборке мозаики.</div>
			{/*<div className="mb10">Телефон</div>
			<ReactInputMask className="mb20" mask="+7 (999) 999-99-99" alwaysShowMask value={phone} onChange={onPhoneChange} type="text" />*/}
			<div className="mb10">E-mail</div>
			<Inputbox className="mb20" onChange={setEmail} />
			<button disabled={!valid || working || saving} onClick={onSubmit}>Отправить схему</button>
		</div>
	</>
	);
}
