import css from './Header.module.scss';
import { Link } from '@gatsbyjs/reach-router';

export default function Header(props) {
    return <div className={css.main}>
        <img src="/bgl.webp" className={css.bgl}/>
        <img src="/bgr.webp" className={css.bgr}/>
        <Link to="/" className={css.simple}><img src="/photo_mosaic_logo.webp" /></Link>
    </div>
}
