import { useState, useEffect } from "react";
import css from "./Ask4Code.module.scss";
import ReactInputMask from "react-input-mask";
import jExec from "../../utils/jExec";
import { CircleLoader } from "react-spinners";
import { Link, navigate } from '@gatsbyjs/reach-router'

export default function AskForCode(props) {
	const [code, setCode] = useState("");
	const [valid, setValid] = useState(false);
	const [error, setError] = useState(false);
	const [check, setCheck] = useState(false);

	const onChange = (ev) => {
		const val = "" + ev.target.value;
		setCode(val.toUpperCase());
	};
	useEffect(() => {
		if (/[A-Za-z0-9]{4}-[A-Za-z0-9]{4}/.test(code)) {
			setValid(true);
		} else {
			setValid(false);
		}
	}, [code]);

	const onCheck = async () => {
		setCheck(true);
		const loaded = await jExec("test/code", { code });
		if (loaded.result) {
			props.onSuccess({ code, ...loaded });
			setError(false);
		} else {
			setError(true);
		}
		setCheck(false);
	};

	if (check) {
		return (
			<div className="well flex-center">
				<div className="mb20">Проверяю код</div>
				<CircleLoader />
			</div>
		);
	}

	return (
		<div className={css.ask4code}>
			{error ? (
				<div className={css.error}>Код не найден</div>
			) : (
				<div>
					В инструкции к набору Вы найдете код. Введите его в поле ниже для получения схемы выкладки.
				</div>
			)}
			<ReactInputMask disabled={check} alwaysShowMask mask="****-****" value={code} onChange={onChange} autoFocus type="text" />
			<div>
				<button className={css.success} disabled={!valid || check} onClick={onCheck}>
					Проверить
				</button>
			</div>
			<div><Link to="/" className={css.default}>На главную страницу</Link></div>
		</div>
	);
}
