import css from "./Create.module.scss";
import { Fragment } from "react";
import DiskImage, { getDiskFilename } from "../../shared/DiskImage";
import { PulseLoader } from "react-spinners";

export default function Schemes(props) {

	return (
		<div className={css.schemes}>
			<div className={css.title}>
				Схемы к коду <span>{props.code}</span>
			</div>
			<div className={css.list}>{props.schemes.map((v, idx) => <Fragment key={idx}>
				<img src={getDiskFilename(v.hash, "128x128")} />
				<div>{v.ts}</div>
				{v.status == 0 ? <><div></div><div><PulseLoader color="#888" size={10}/></div><div></div></> : <>
					<div><a href={"/scheme/" + v.pdf} target="_blank"><i className="bi bi-filetype-pdf"/> Открыть</a></div>
					<div><a href={"https://belosnezhkafoto.art/download/" + v.pdf} target="_blank"><i className="bi bi-cloud-download"/> Скачать</a></div>
					<button onClick={()=>props.onSelect(v.hash)} className="medium"><i className="bi bi-envelope"/> Отправить</button>
				</>}
			</Fragment>)}</div>
		</div>
	);
}
