import React, { useEffect, useState } from 'react'
import { CircleLoader } from "react-spinners";
import jExec from '../../utils/jExec';
import css from './Scheme.module.scss'

function Dot (props) {
    return <div style={{backgroundColor:props.color, color:props.word_color}}>
        {props.word}
        <div>{props.count}</div>
    </div>
}

function Block ({data, colors}) {
    const y = data.length ;
    const x = data[0].length ;
    let rows = [] ;
    let cols = [] ;
    cols.push(<div key={cols.length}/>) ;
    for (let i=0;i<x;i++) cols.push(<div key={cols.length}>{i+1}</div>)
    rows.push(<div className={css.axisX} key={rows.length}>{cols}</div>)

    for (let j = 0 ; j < y ; j++) {
        let cols = [] ;
        cols.push(<div className={css.axisY} key={cols.length}>{j+1}</div>)
        let cur = false, count = 0 ;
        for (let i=0;i<x;i++) {
            if (colors[data[j][i]] === cur) {
                count ++ ;
            } else {
                cur=colors[data[j][i]];
                count = 1 ;
            }
            cols.push(<Dot key={cols.length} count={count} {...colors[data[j][i]]}/>)
        }

        rows.push(<div key={rows.length}>{cols}</div>)
    }

    return <div className={css.block}>{rows}</div> ;
}

export default function Scheme(props) {
    const [blocks, setBlocks] = useState(false);
    const [colors, setColors] = useState(false);
    const [block, setBlock] = useState(0);
    const [hash, setHash] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            const loaded = await jExec('test/scheme', { pdf: props.hash });
            setBlocks(loaded.blocks || []);
            setColors(loaded.colors || []);
            setHash(loaded.hash || false);
            setLoading(false);
        })();
    }, [props.hash]);

    if (loading) return (<CircleLoader color='#aaa' size={120} />);

    return <div className={css.main}>
        <div className={css.title}>Фрагмент {block+1}</div>
        <Block data={blocks[block]} colors={colors}/>
        <div className={css.pages}>
            <button disabled={block==0} onClick={()=>setBlock(block-1)}><i className="bi bi-arrow-left"></i></button>
            <div className={css.cards}>{blocks.map((v, idx) => <button className={idx == block ? css.active : ''} onClick={()=>setBlock(idx)}>{idx + 1}</button>)}</div>
            <button disabled={block==blocks.length-1} onClick={()=>setBlock(block+1)}><i className="bi bi-arrow-right"></i></button>
        </div>
    </div>
}
