import React, { Component } from 'react'

export default class Inputbox extends Component {
    state = {
        value: '',
        oldValue: '',
    }
    ref = null;
    saveTimeout = false;
    constructor(props) {
        super(props);
        if (typeof this.props.value != 'undefined') this.state.value = this.props.value;
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.props.value != prevProps.value) this.setState({ value: this.props.value });
    }
    onChange = (e) => {
        this.setState({ value: e.target.value }, () => {
            if (this.props.onChange) this.props.onChange(this.state.value);
        })
        if (this.props.autoSave) {
            if (this.saveTimeout) clearTimeout(this.saveTimeout);
            this.saveTimeout = setTimeout(this.onChanged, this.props.autoSave);
        }
    }
    onKeyDown = (e) => {
        if (e.which == 13) this.onChanged(true)
    }
    onBlur = () => {
        if (this.props.onBlur) this.props.onBlur();
        this.onChanged()
    }
    onFocus = () => {
        if (this.props.onFocus) this.props.onFocus();
        this.setState({ oldValue: this.state.value });
    }
    onChanged = (submit) => {
        if (this.saveTimeout) clearTimeout(this.saveTimeout);
        if (this.props.onChanged && (this.state.value != this.state.oldValue)) {
            this.props.onChanged(this.state.value, submit)
            this.setState({ oldValue: this.state.value });
        }
    }
    render() {
        let props = { type: "text" };
        if (this.props.style) props.style = this.props.style;
        if (this.props.type) props.type = this.props.type;
        if (this.props.className) props.className = this.props.className;
        if (this.props.placeholder) props.placeholder = this.props.placeholder;
        if (this.props.maxLength) props.maxLength = this.props.maxLength;
        if (this.props.disabled) props.disabled = this.props.disabled;
        return (
            <input ref={(c) => this.ref = c} {...props} value={this.state.value} onChange={this.onChange} onFocus={this.onFocus} onBlur={this.onBlur} onKeyDown={this.onKeyDown} />
        )
    }
}
