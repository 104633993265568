import UploadButton from '../UploadButton/UploadButton'

import css from './Try.module.scss'

import jExec from '../../utils/jExec'
import { Link, navigate } from '@gatsbyjs/reach-router'

export default function Try(props) {
    const process = async (files) => {
        const done = await jExec('test/upload', { files })
        if (done.result && done.hash) {
            navigate('/try/' + done.hash);
        }
    }

    return (
        <div className={css.main}>
            <div>
                <div>Загрузите любую картинку или фотографию</div>
                <UploadButton process={process} try />
            </div>
            <div><Link to="/" className={css.default}>Назад</Link></div>
        </div>
    )
}
