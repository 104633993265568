import css from './Buy.module.scss' ;

export default function Buy() {
    return (
        <div className={css.buy}>
            <div>Покупайте наши наборы на всех маркетплейсах и в нашем фирменном интернет-магазине</div>
            <div>
                <a target="_blank" rel="noreferrer" href="https://belosnezhka.ru/search/?tag=%D1%84%D0%BE%D1%82%D0%BE%D0%BC%D0%BE%D0%B7%D0%B0%D0%B8%D0%BA%D0%B0"><img src="/shops/bzh.webp" /></a>
                <a target="_blank" rel="noreferrer" href="https://www.wildberries.ru/seller/28295"><img src="/shops/wb.webp" /></a>
                <a target="_blank" rel="noreferrer" href="https://www.ozon.ru/seller/shedevr-6261/products/?miniapp=seller_6261"><img src="/shops/ozon.webp" /></a>
                <a target="_blank" rel="noreferrer" href="https://market.yandex.ru/business--shedevr-store/920011"><img src="/shops/ymarket.webp" /></a>
                <a target="_blank" rel="noreferrer" href="https://sbermegamarket.ru/shop/shedevr/catalog/knigi-hobbi-kancelyariya/#?filters=%7B%222B0B1FF4756D49CF84B094522D57ED3D%22%3A%5B%22Art%20of%20Dream%22%2C%22%D0%91%D0%B5%D0%BB%D0%BE%D1%81%D0%BD%D0%B5%D0%B6%D0%BA%D0%B0%22%2C%22Miss%20Kiss%22%2C%22Perfect%20craft%22%5D%7D"><img src="/shops/sber.webp" /></a>
                <a target="_blank" rel="noreferrer" href="https://shedevr.aliexpress.ru/store/group/%D0%91%D0%B5%D0%BB%D0%BE%D1%81%D0%BD%D0%B5%D0%B6%D0%BA%D0%B0/5243197_1000000376944.html?spm=a2g0o.store_pc_groupList.0.0.79db647d0RFSVC"><img src="/shops/aliexpress.webp" /></a>
            </div>
        </div>
    )
}
