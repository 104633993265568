
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './App.css';
import { Router } from '@gatsbyjs/reach-router';
import Page404 from './app/Page404/Page404';
import Main from './app/Main/Main';
import Try from './app/Try/Try';
import Position from './app/Position/Position';
import Create from './app/Create/Create';
import Buy from './app/Buy/Buy';
import Header from './app/Header/Header';
import Scheme from './app/Scheme/Scheme';
import Stats from './app/Stats/Stats';

if (process.env.NODE_ENV === 'production') console.log = function () {};

function App() {
	return (
		<div className="app">
			<Router className="app-header">
				<Header default simple/>
			</Router>
			<Router className="app-body">
				<Main path="/" />
				<Position path="/try/:hash" try/>
				<Try path="/try"/>
				<Create path="/create"/>
				<Create path="/create/:code"/>
				<Create path="/create/:code/:hash"/>
				<Scheme path="/scheme/:hash"/>
				<Stats path="/blablablablabla"/>
				<Page404 default />
			</Router>
			<Buy/>
		</div>
	);
}

export default App;
