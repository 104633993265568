import React, { Component } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component';

export function getDiskFilename(hash, size) {

	let host = ['rem','localhost','sky'].includes(window.location.hostname) ? 'https://bzh.sinel.me' : '' ;

	return host + '/img/' + hash.substr(0, 3) + '/' + hash.substr(3, 3) + '/' + hash.substr(6, 3) + '/' + hash.substr(9) + '/nb_' + size + '.webp';
}

export default function DiskImage(props) {
	return <LazyLoadImage src={getDiskFilename(props.hash, props.size)} alt={props.alt} />
}

