import React from 'react'

import css from './Page404.module.scss'

export default function Page404() {
  return (
    <div className={css.main}>
        <h1>Ошибка 404</h1>
        <div>Страница не найдена</div>
    </div>
  )
}
