import React, { Component } from 'react'
import PropTypes from 'prop-types';

import styles from './styles.module.css';

const deltaZoom = 0.1;

class ImageAreaSelector extends Component {
	state = {
		ready: false,
		loaded: false,
		serial: 0,
		left: false,
		top: false,
		zoom: false,
		frame: {},
		moving: false,
		width: 0,
		height: 0,
		rotate: 0
	}
	imgRef = React.createRef();
	redraw = () => {
		this.setState({ serial: this.state.serial + 1 });
	}
	preventDefault = e => e.preventDefault();
	constructor(props) {
		super(props);
		this.divRef = React.createRef();
	}
	onResize = e => {
		if (this.state.width == this.divRef.current.clientWidth && this.state.height == this.divRef.current.clientHeight) return;
		this.setState({ width: this.divRef.current.clientWidth, height: this.divRef.current.clientHeight }, this.positionFrame);
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevProps.aspect != this.props.aspect) this.positionFrame();
	}

	width = () => {
		return (this.state.rotate == 1 || this.state.rotate == 3) ? this.props.imageHeight : this.props.imageWidth
	}

	height = () => {
		return (this.state.rotate == 1 || this.state.rotate == 3) ? this.props.imageWidth : this.props.imageHeight
	}


	positionFrame = () => {

		let iW = Math.min(this.state.width - 20, this.width());
		let iH = Math.min(this.state.height - 20, this.height());

		console.log (iW,iH) ;

		let originalAspect = iW / iH;

		let frame = false;
		if (originalAspect >= this.props.aspect) {
			//console.log('wider',iW, iH / this.props.aspect);
			frame = {
				width: iH * this.props.aspect,
				height: iH,
				top: (this.state.height - iH) / 2,
				left: (this.state.width - iH * this.props.aspect) / 2
			}
			//if (frame.width < this.state.width - 20) this.minZoom = frame.width / this.width() ;
		} else {
			//console.log('taller');
			frame = {
				width: iW,
				height: iW / this.props.aspect,
				left: (this.state.width - iW) / 2,
				top: (this.state.height - iW / this.props.aspect) / 2
			}
			//if (frame.height < this.state.height - 20) this.minZoom = frame.height / this.height() ;
		}

		let minZoom = Math.max(frame.height / this.height(), frame.width / this.width());
		if (this.state.left === false) {
			this.state.left = 0;
			this.state.top = 0;
			if (this.props.default) {
				this.state = { ...this.state, ...this.props.default };
			} else {
				this.state.zoom = minZoom;
			}
		} else {
			if (this.state.zoom < minZoom) this.state.zoom = minZoom;
		}

		this.setState({ ready: true, frame, left: this.state.left, top: this.state.top, zoom: this.state.zoom, rotate: this.state.rotate }, this.fixPosition);
	}
	fixPosition() {
		let left = this.fixLeft(this.state.left, this.state.zoom);
		let top = this.fixTop(this.state.top, this.state.zoom);
		let set = {};
		if (this.state.left != left) set.left = left;
		if (this.state.top != top) set.top = top;
		this.setState(set, this.onChange);
	}
	componentDidChange() {
		this.positionFrame();
	}
	componentDidMount() {
		this.onResize();
		window.addEventListener('resize', this.onResize);
		this.divRef.current.addEventListener('wheel', this.preventDefault);
		this.imgRef.addEventListener('load', () => {
			this.setState({ loaded: true });
		});
	}
	componentWillUnmount() {
		window.removeEventListener('resize', this.onResize);
		this.divRef.current.removeEventListener('wheel', this.preventDefault);
	}

	onMouseDown = (e) => {
		this.moving.clientX = e.clientX;
		this.moving.clientY = e.clientY;
		window.addEventListener('mousemove', this.onMouseMove);
		window.addEventListener('mouseup', this.onMouseUp);
	}
	moving = [];
	onMouseMove = (e) => {
		let deltaX = e.clientX - this.moving.clientX, deltaY = e.clientY - this.moving.clientY;
		this.moving.clientX = e.clientX;
		this.moving.clientY = e.clientY;
		this.moveImage(deltaX, deltaY);
	}
	moveImage(deltaX, deltaY) {
		this.setState({
			left: this.fixLeft(this.state.left + deltaX * 100 / (this.width() * this.state.zoom)),
			top: this.fixTop(this.state.top + deltaY * 100 / (this.height() * this.state.zoom)),
		});
	}
	fixLeft = (left, zoom) => {
		if (typeof zoom === 'undefined') zoom = this.state.zoom;
		left = Math.min(left / 100 * this.width() * zoom, (this.width() * zoom - this.state.frame.width) / 2);
		left = Math.max(left, (this.state.frame.width - this.width() * zoom) / 2)
		return left * 100 / (this.width() * zoom);
	}
	fixTop = (top, zoom) => {
		if (typeof zoom === 'undefined') zoom = this.state.zoom;
		top = Math.min(top / 100 * this.height() * zoom, (this.height() * zoom - this.state.frame.height) / 2);
		top = Math.max(top, (this.state.frame.height - this.height() * zoom) / 2)
		return top * 100 / (this.height() * zoom);
	}
	onMouseUp = (e) => {
		window.removeEventListener('mousemove', this.onMouseMove);
		window.removeEventListener('mouseup', this.onMouseUp);
		this.onChange();
	}

	onMouseWheel = (e) => {
		let zoom = this.state.zoom;
		if (e.deltaY > 0) {
			zoom = Math.max(Math.max(this.state.frame.height / this.height(), this.state.frame.width / this.width()), this.state.zoom - deltaZoom);
		} else {
			zoom = this.state.zoom + deltaZoom
		}
		if (zoom != this.state.zoom) this.zoomImage(zoom);
	}
	zoomImage(zoom) {
		let z = Math.max(Math.max(this.state.frame.height / this.height(), this.state.frame.width / this.width()), zoom)
		let set = { zoom: z };
		set.left = this.fixLeft(this.state.left, z);
		set.top = this.fixLeft(this.state.top, z);
		this.setState(set, this.fixPosition);
	}

	zoomOut = () => {
		this.zoomImage(this.state.zoom * 0.9)
	}
	zoomIn = () => {
		this.zoomImage(this.state.zoom * 1.1)
	}
	noZoom = () => {
		this.zoomImage(false)
	}
	rotateLeft = () => {
		this.setState({ rotate: this.state.rotate ? this.state.rotate - 1 : 3 }, this.positionFrame);
	}

	rotateRight = () => {
		this.setState({ rotate: this.state.rotate < 3 ? this.state.rotate + 1 : 0 }, this.positionFrame);
	}

	zooming = {};
	onTouchStart = (e) => {

		this.moving.clientX = e.touches[0].clientX;
		this.moving.clientY = e.touches[0].clientY;
		if (e.touches[1]) {
			this.zooming = { clientX: e.touches[1].clientX, clientY: e.touches[1].clientY, zoom: this.state.zoom };
		}
		e.preventDefault();
		this.divRef.current.addEventListener('touchmove', this.onTouchMove);
		this.divRef.current.addEventListener('touchend', this.onTouchEnd);
		this.divRef.current.addEventListener('touchcancel', this.onTouchCancel);
	}
	onTouchMove = (e) => {
		e.preventDefault();

		console.log(e.touches[0].clientX, e.touches[0].clientY);
		if (e.touches[1]) {
			if (this.zooming === false) {
				this.zooming = { clientX: e.touches[1].clientX, clientY: e.touches[1].clientY, zoom: this.state.zoom };
				return;
			}
			//this.setState({info:'zooming ' + this.moving.clientX + ' ' + this.zooming.clientX});
			let oldDistance = Math.sqrt(Math.pow(this.moving.clientX - this.zooming.clientX, 2) + Math.pow(this.moving.clientY - this.zooming.clientY, 2));
			let newDistance = Math.sqrt(Math.pow(e.touches[0].clientX - e.touches[1].clientX, 2) + Math.pow(e.touches[0].clientY - e.touches[1].clientY, 2));

			let zoom = this.zooming.zoom * newDistance / oldDistance;
			if (oldDistance > newDistance) {
				zoom = Math.max(Math.max(this.state.frame.height / this.height(), this.state.frame.width / this.width()), zoom);
			}
			if (oldDistance) this.zoomImage(zoom);
		} else {
			this.zooming = false;
			let deltaX = e.touches[0].clientX - this.moving.clientX, deltaY = e.touches[0].clientY - this.moving.clientY;
			this.moving.clientX = e.touches[0].clientX;
			this.moving.clientY = e.touches[0].clientY;
			this.moveImage(deltaX, deltaY);
		}
	}
	onTouchEnd = (e) => {
		e.preventDefault();
		this.onChange();
		this.clearTouchHandlers();
	}
	onTouchCancel = (e) => {
		e.preventDefault();
		this.clearTouchHandlers();
	}
	clearTouchHandlers = () => {
		this.divRef.current.removeEventListener('touchmove', this.onTouchMove);
		this.divRef.current.removeEventListener('touchend', this.onTouchEnd);
		this.divRef.current.removeEventListener('touchcancel', this.onTouchCancel);
	}

	onChange = () => {
		let params = {
			left: this.width() / 2 - this.state.frame.width / 2 / this.state.zoom - this.state.left / 100 * this.width(),
			top: this.height() / 2 - this.state.frame.height / 2 / this.state.zoom - this.state.top / 100 * this.height(),
			width: this.state.frame.width / this.state.zoom,
			height: this.state.frame.height / this.state.zoom,
		}
		for (let x in params) params[x] = Math.round(params[x]);
		if (this.props.onChange) this.props.onChange({ area: params, settings: { left: this.state.left, top: this.state.top, zoom: this.state.zoom, rotate: this.state.rotate } });
	}
	render() {

		let fw = (this.state.rotate == 1 || this.state.rotate == 3) ? this.state.frame.height : this.state.frame.width;
		let fh = (this.state.rotate == 1 || this.state.rotate == 3) ? this.state.frame.width : this.state.frame.height;

		let style = {
			opacity: this.state.loaded ? 1 : 0,
			left: this.state.frame.left + fw / 2 + this.state.left / 100 * this.width() * this.state.zoom - this.width() * this.state.zoom / 2,
			top: this.state.frame.top + fh / 2 + this.state.top / 100 * this.height() * this.state.zoom - this.height() * this.state.zoom / 2,
			width: this.props.imageWidth * this.state.zoom,
			height: this.props.imageHeight * this.state.zoom,
		};

		if (this.state.rotate == 1 || this.state.rotate == 3) {
			if (this.props.imageWidth > this.props.imageHeight) {
				style.left -= this.state.frame.left * this.state.zoom;
				style.top += this.state.frame.top * this.state.zoom;
			}
			if (this.props.imageWidth < this.props.imageHeight) {
				style.left += this.state.frame.left * this.state.zoom;
				style.top -= this.state.frame.top * this.state.zoom;
			}
		}

		style.left = + style.left || 0;
		style.top = + style.top || 0;

		if (this.state.rotate == 1) style.transform = 'rotate(90deg)';
		if (this.state.rotate == 2) style.transform = 'rotate(180deg)';
		if (this.state.rotate == 3) style.transform = 'rotate(270deg)';

		let opts = {};
		if (this.props.style) opts.style = this.props.style;

		return (
			<>
				<div ref={this.divRef}
					{...opts}
					onWheel={this.onMouseWheel}
					onMouseDown={this.onMouseDown}
					onTouchStart={this.onTouchStart}
					className={styles.window + ' prevent-select'}>
					<div className={styles.frame} style={this.state.frame} />
					<img draggable={false}
						style={style}
						ref={(ref) => this.imgRef = ref} src={this.props.image} />
					{this.state.info ? <div className={styles.info}>
						{this.state.info}
					</div> : null}
				</div>
				<div className={styles.buttons}>
					<button onClick={this.zoomOut}><i className="bi bi-dash-lg"></i></button>
					<button onClick={this.zoomIn}><i className="bi bi-plus-lg"></i></button>
					<button onClick={this.rotateLeft}><i className="bi bi-arrow-counterclockwise"></i></button>
					<button onClick={this.rotateRight}><i className="bi bi-arrow-clockwise"></i></button>
					<button onClick={this.noZoom}><img src="/svg/zoom.svg" style={{ width: 20 }} /></button>
				</div>
			</>
		)
	}
}

ImageAreaSelector.propTypes = {
	image: PropTypes.string,
}

export default ImageAreaSelector;