import UploadButton from '../UploadButton/UploadButton'
import css from './UploadPhoto.module.scss'
import jExec from '../../utils/jExec'

export default function UploadPhoto(props) {
    const process = async (files) => {
        const done = await jExec('test/upload', { files })
        if (done.result && done.hash) {
            props.onUpload(done.hash) ;
        }
    }

    return (
        <div className={css.main}>
            <div>Загрузи любую картинку или фотографию</div>
            <UploadButton process={process}/>

            
        </div>
    )
}
