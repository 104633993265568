import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

//import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//serviceWorkerRegistration.register();
//serviceWorkerRegistration.register('/service-worker-workbox.js');
//navigator.serviceWorker.register('/service-worker-workbox.js')

// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('/service-worker-workbox.js')
//       .then(function(registration) {
//         console.log('Service Worker зарегистрирован успешно:', registration);
//       })
//       .catch(function(error) {
//         console.log('Ошибка регистрации Service Worker:', error);
//       });
//   }


// Проверяем поддержку СВ

if (!(!process.env.NODE_ENV || process.env.NODE_ENV === 'development')) {

  if ('serviceWorker' in navigator) {
    // Ожидаем полной загрузки страницы
    window.addEventListener('load', () => {
      navigator.serviceWorker.register('/service-worker.js')
    })
  }
}