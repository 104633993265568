import React from 'react'
import { CircleLoader } from 'react-spinners'

import css from './UploadButton.module.scss'

export default class UploadButton extends React.Component {
    state = { working: false }

    onUploadClick = async () => {
        this.setState({ working: true })
        await this.props.process(this.uploadRef.files)
        this.setState({ working: false })
    }

    render() {
        return (
            <div>
                <input style={{ display: 'none' }} 
                    onChange={this.onUploadClick}
                    multiple={this.props.multiple || false} 
                    ref={(c) => { this.uploadRef = c; }} 
                    type="file" accept="image/*" />
                <button className={css.button} disabled={this.state.working} onClick={() => this.uploadRef.click()} >
                    {this.state.working ? <CircleLoader color="#ccc" size={30}/> : 'Загрузить'}
                </button>
            </div>
        )
    }
}